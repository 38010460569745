<template>
  <div class="select-delivery-location">
    <Dialog
      :is-show-dialog="isShowSelectionDeliveryLocationDialogValue"
      title="ที่อยู่จัดส่ง"
      @setIsShowDialog="setIsShowSelectionDeliveryLocationDialog"
    >
      <div
        v-for="({ id, name, address, phoneNumber, isDefault }, key) in sortedDeliveryLocations"
        :key="key"
        :class="getClassName('location mb-3 d-flex', isDefault)"
        @click="handleClickChangeDeliveryLocation(id)"
      >
        <div :class="getClassName('location-info', false)">
          <Location
            :name="name"
            :address="address"
            :phone-number="phoneNumber"
          />
        </div>
        <div
          v-if="isDefault"
          :class="getClassName('location-mark', false)"
        >
          <img
            class="mr-1"
            src="@/assets/images/logo/tick-purple.png"
            srcset="@/assets/images/logo/tick-purple@2x.png 2x,
            @/assets/images/logo/tick-purple@3x.png 3x"
          >
          <span>ที่อยู่จัดส่งหลัก</span>
        </div>
      </div>

      <div class="add-delivery-location">
        <img
          class="add-delivery-location-icon mr-1"
          src="@/assets/images/logo/plus.svg"
        >
        <span
          class="add-delivery-location-text"
          @click="setIsShowAddDeliveryLocationDialog(true)"
        >
          เพิ่มที่อยู่จัดส่ง
        </span>
      </div>
    </Dialog>

    <AddDeliveryLocation
      :is-show-add-delivery-location-dialog="isShowAddDeliveryLocationDialog"
      @setIsShowAddDeliveryLocationDialog="setIsShowAddDeliveryLocationDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Dialog from '@/components/Dialog.vue';
import CloseIcon from '@/assets/images/logo/close.svg';
import Location from '@/components/Location.vue';

import {
  UPDATE_DEFAULT_SHIPPING,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
} from '@/store/_actionTypes';

import AddDeliveryLocation from './AddDeliveryLocation.vue';

export default {
  name: 'SelectDeliveryLocation',
  components: {
    Location,
    AddDeliveryLocation,
    Dialog,
  },
  props: {
    deliveryLocations: {
      type: Array,
      default: () => [],
    },
    isShowSelectionDeliveryLocationDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    closeIcon: CloseIcon,
    isShowAddDeliveryLocationDialog: false,
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapState('user', ['accessToken']),
    isShowSelectionDeliveryLocationDialogValue: {
      get() {
        return this.isShowSelectionDeliveryLocationDialog;
      },
    },
    sortedDeliveryLocations() {
      let sorted = [...this.deliveryLocations];
      sorted = sorted.sort((a, b) => b.isDefault - a.isDefault);
      return sorted;
    },
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR]),
    ...mapActions('user', [ UPDATE_DEFAULT_SHIPPING ]),
    getClassName(defaultClassName, isDefault) {
      const className = this.isMobileScreenSize ? `${defaultClassName} mobile` : defaultClassName;
      return isDefault ? `${className} active` : className;
    },
    setIsShowSelectionDeliveryLocationDialog(value) {
      this.$emit('setIsShowSelectionDeliveryLocationDialog', value);
    },
    setIsShowAddDeliveryLocationDialog(value) {
      this.isShowAddDeliveryLocationDialog = value;
    },
    async handleClickChangeDeliveryLocation(shippingId) {
      try {
        await this[UPDATE_DEFAULT_SHIPPING]({ accessToken: this.accessToken, shippingId });

        this[CHANGE_SNACKBAR_MESSAGE]('เลือกที่อยู่จัดส่งหลักสำเร็จ');
        this[CHANGE_SNACKBAR_COLOR]('green');
        this[SHOW_SNACKBAR](true);
      } catch (error) {
        this[CHANGE_SNACKBAR_MESSAGE]('ล้มเหลว กรุณาลองใหม่อีกครั้ง');
        this[CHANGE_SNACKBAR_COLOR]('red');
        this[SHOW_SNACKBAR](true);
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/fonts';

  .location {
    cursor: pointer;
    border-radius: 6px;
    border: solid 2px $white-01;
    background-color: $white-00;
    padding: 4%;
    width: 100%;

    &.mobile {
      flex-direction: column-reverse;
    }

    &.active {
      border-color: $primary-color-02;
    }

    &-info {
      width: 80%;

      &.mobile {
        width: 100%;
      }
    }

    &-mark {
      width: 20%;
      @include kanit;
      font-size: 12px;
      line-height: 1.5;
      color: $primary-color-02;

      &.mobile {
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
  .add-delivery-location {
    cursor: pointer;

    &-icon {
      width: 14px;
      height: 14px;
    }

    &-text {
      @include kanit;
      font-size: 12px;
      line-height: 1.5;
      color: $primary-color-02;
    }
  }
</style>
