<template>
  <Dialog
    :is-show-dialog="isShowOrderSuccessDialogValue"
    @setIsShowDialog="setIsShowOrderSuccessDialog"
  >
    <div class="order-success d-flex flex-column align-center">
      <img
        class="mr-1 mb-3 icon-image"
        src="@/assets/images/logo/tick-green.png"
        srcset="@/assets/images/logo/tick-green@2x.png 2x,
        @/assets/images/logo/tick-green@3x.png 3x"
      >
      <div class="text header-1 mb-2">
        สร้างคำสั่งซื้อสำเร็จ
      </div>
      <div 
        v-if="userCredit"
        class="text header-2 mb-2"
      >
        เครดิตการโอนเงิน {{ userCredit }} วัน
      </div>
      <div class="text mb-2">
        {{ getNote() }}
      </div>
      <div class="text mb-2">
        พนักงานจะติดต่อท่านเพื่อยืนยันการสั่งซื้อค่ะ ขอบคุณที่ใช้บริการนรินทร์เภสัชค่ะ
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'OrderSuccess',
  components: {
    Dialog,
  },
  props: {
    isShowOrderSuccessDialog: {
      type: Boolean,
      default: () => false,
    },
    userCredit: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    isShowOrderSuccessDialogValue: {
      get() {
        return this.isShowOrderSuccessDialog;
      },
    },
  },
  methods: {
    setIsShowOrderSuccessDialog(value) {
      this.$emit('setIsShowOrderSuccessDialog', value);
      if (value === false) {
        this.$router.push({ name: 'OrderHistory' });
      }
    },
    getNote() {
      return this.userCredit 
        ? `กรุณาโอนเงินและแจ้งชำระเงินภายในเวลา ${this.userCredit} วันหลังจากสร้างคำสั่งซื้อสำเร็จ`
        : 'กรุณาโอนเงินและแจ้งชำระเงิน';
    }
  },
};
</script>

<style lang="scss">
.order-success {
  .icon-image {
    width: fit-content;
  }

  .text {
    font-family: Kanit;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    font-size: 14px;
    color: $black-00;

    &.header-1 {
      font-size: 20px;
      color: $black-00;
    }

    &.header-2 {
      font-size: 24px;
      font-weight: 500;
      color: $primary-color-03;
    }
  }
}
</style>
