var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-title mb-3"},[_vm._v(" สรุปรายการสั่งซื้อ ")]),_c('div',{staticClass:"order-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getNormalProductSelected,"disable-sort":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.order",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text header-left"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.price",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text header"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text header"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.unit",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text header"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.totalPrice",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text header"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.getClassName(_vm.isMobileScreenSize, 'd-flex', 'justify-space-between align-start')},[(_vm.getProductImage(item))?_c('div',{staticClass:"product-image-container mr-4"},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.getProductImage(item)}})]):_vm._e(),_c('div',[_c('div',[_c('div',{staticClass:"order-title-text"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("/product/" + (item.id))}},[_vm._v(_vm._s(_vm.getTitle(item)))])]),_c('div',{staticClass:"order-subtitle-text"},[_vm._v(" "+_vm._s(_vm.getSubTitle(item))+" ")])])])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.isDiscountPromotionValid(item))?_c('span',{staticClass:"text discount-price mr-2"},[_vm._v(_vm._s(_vm.getPriceRateItemWithoutDiscount(item)))]):_vm._e(),_c('span',{staticClass:"text p-relative"},[_vm._v(" "+_vm._s(_vm.getPriceRateItem(item))+" "),(_vm.isShippingFeeExisted(item))?_c('div',{staticClass:"d-flex justify-end shipping-fee-container"},[_c('span',{staticClass:"text shipping-fee-title mr-2"},[_vm._v("ค่าส่ง")]),_c('span',{staticClass:"text shipping-fee"},[_vm._v(_vm._s(_vm.getPriceText(item.deliveryFee)))])]):_vm._e()])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"p-relative"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.getAmountText(item.qty)))]),(_vm.isFreeGiftPromotionValid(item))?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getExtraAmountText(item)))]):_vm._e(),(_vm.isShippingFeeExisted(item))?_c('div',{staticClass:"d-flex justify-end justify-md-center shipping-fee-container"},[_c('span',{staticClass:"text shipping-fee"},[_vm._v(_vm._s(_vm.getAmountText(item.qty)))]),(_vm.isFreeGiftPromotionValid(item))?_c('span',{staticClass:"text shipping-fee ml-1"},[_vm._v(" "+_vm._s(_vm.getExtraAmountText(item)))]):_vm._e()]):_vm._e()])])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.unit))])])]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{staticClass:"text total-price p-relative"},[_vm._v(" "+_vm._s(_vm.getItemTotalPrice(item))+" "),(_vm.isShippingFeeExisted(item))?_c('div',{staticClass:"d-flex justify-end justify-md-center shipping-fee-container"},[_c('span',{staticClass:"text shipping-fee"},[_vm._v(_vm._s(_vm.getTotalShippingFee(item)))])]):_vm._e()])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }