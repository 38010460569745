<template>
  <Dialog
    :is-show-dialog="isShowOrderFailedDialogValue"
    @setIsShowDialog="setIsShowOrderFailedDialog"
  >
    <div class="order-failed d-flex flex-column align-center">
      <v-icon class="icon mb-3">
        mdi-close-circle
      </v-icon>
      <div class="text header-1 mb-2">
        สร้างคำสั่งซื้อล้มเหลว
      </div>
      <div
        v-if="errorMessage"
        class="text mb-2"
      >
        {{ errorMessage }}
      </div>
      <div
        v-else
        class="text mb-2"
      >
        กรุณาลองใหม่อีกครั้ง
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'OrderFailed',
  components: {
    Dialog,
  },
  props: {
    isShowOrderFailedDialog: {
      type: Boolean,
      default: () => false,
    },
    errorMessage: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isShowOrderFailedDialogValue: {
      get() {
        return this.isShowOrderFailedDialog;
      },
    },
  },
  methods: {
    setIsShowOrderFailedDialog(value) {
      this.$emit('setIsShowOrderFailedDialog', value);
    },
  },
};
</script>

<style lang="scss">
.order-failed {
  .icon {
    font-size: 50px;
    color: $red-color-00;
  }

  .text {
    font-family: Kanit;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    font-size: 14px;
    color: $black-00;

    &.header-1 {
      font-size: 20px;
      color: $black-00;
    }

    &.header-2 {
      font-size: 24px;
      font-weight: 500;
      color: $primary-color-03;
    }
  }
}
</style>
