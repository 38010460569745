<template>
  <div class="d-flex flex-column mt-3">
    <!-- delivery location -->
    <div class="mb-4">
      <div class="text-title mb-2">
        ข้อมูลการจัดส่ง
      </div>
      <div class="d-flex mb-2">
        <div class="sub-title mr-2">
          ที่อยู่จัดส่ง:
        </div>
        <div>
          <img
            class="mr-1"
            :src="editIcon"
          >
          <span
            class="edit-text"
            @click="setIsShowSelectionDeliveryLocationDialog(true)"
          >
            <span v-if="activeDeliveryLocation">เปลี่ยน</span>
            <span v-else>เพิ่ม</span>
          </span>
        </div>
      </div>

      <div v-if="!activeDeliveryLocation">
        ไม่มี
      </div>
      <Location
        v-else
        :name="activeDeliveryLocation.name"
        :address="activeDeliveryLocation.address"
        :phone-number="activeDeliveryLocation.phoneNumber"
      />
    </div>
    <v-divider class="mb-4" />

    <!-- where to pick -->
    <div>
      <div class="sub-title mr-2">
        สถานที่นัดรับ/จัดส่งสินค้า:
      </div>
      <v-radio-group
        v-model="pickupLocationValue"
        class="radio"
        :rules="pickupLocationRules"
      >
        <v-radio
          v-for="({ title, description, iconImage, value }) in pickupLocations"
          :key="title"
          :value="value"
        >
          <template v-slot:label>
            <div class="d-flex flex-column mb-3">
              <div class="d-flex">
                <div class="radio-title">
                  {{ title }}
                </div>
                <img
                  v-if="iconImage"
                  class="icon-description ml-1 mt-1"
                  :src="iconImage"
                >
              </div>
              <div
                v-for="(desc) in description"
                :key="desc"
              >
                <span class="radio-description">{{ desc }}</span>
              </div>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

    <SelectDeliveryLocation
      :delivery-locations="deliveryLocations"
      :is-show-selection-delivery-location-dialog="isShowSelectionDeliveryLocationDialog"
      @setIsShowSelectionDeliveryLocationDialog="setIsShowSelectionDeliveryLocationDialog"
    />
  </div>
</template>

<script>
import EditIcon from '@/assets/images/logo/pencil-edit-button.png';
// import QuestionIcon from '@/assets/images/logo/question.png';

import { PICKUP_LOCATIONS } from '@/helpers/pickupLocations';
import Location from '@/components/Location.vue';
import SelectDeliveryLocation from './SelectDeliveryLocation.vue';

export default {
  name: 'Delivery',
  components: {
    SelectDeliveryLocation,
    Location,
  },
  props: {
    activeDeliveryLocation: {
      type: Object,
      default: () => {},
    },
    deliveryLocations: {
      type: Array,
      default: () => [],
    },
    pickupLocation: {
      type: String,
      default: () => '',
    },
    pickupLocationRules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editIcon: EditIcon,
    isShowSelectionDeliveryLocationDialog: false,
  }),
  computed: {
    pickupLocationValue: {
      get() {
        return this.pickupLocation;
      },
      set(value) {
        this.$emit('setPickupLocation', value);
      },
    },
    pickupLocations() {
      return PICKUP_LOCATIONS;
    },
  },
  methods: {
    setIsShowSelectionDeliveryLocationDialog(value) {
      this.isShowSelectionDeliveryLocationDialog = value;
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/fonts';
  .radio-title {
    @include sarabun;
    font-size: 14px;
    line-height: 1.57;
    color: $black-00;
  }
  .radio-description {
    @include sarabun;
    font-size: 14px;
    line-height: 1.57;
    color: $black-00;
  }
  .radio {
    .v-radio {
      display: flex !important;
      align-items: flex-start !important;
    }
  }
  .icon-description {
    width: 14px;
    height: 14px;
  }
</style>
