<template>
  <div class="d-flex flex-column mt-3">
    <div class="text-title mb-2">
      สรุปการชำระเงิน
    </div>

    <!-- payment option -->
    <div class="payment-option mb-4">
      <div class="sub-title mr-2">
        รูปแบบการชำระเงิน:
      </div>
      <v-radio-group
        v-model="paymentOptionValue"
        :rules="paymentOptionRules"
        class="radio"
      >
        <v-radio
          v-for="{ title, value } in paymentOptions"
          :key="title"
          :value="value"
          :disabled="value == 1 && pickupLocation == 'general-provider'"
        >
          <template v-slot:label>
            <div class="radio-title">
              {{ title }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div
        v-for="({
          accountName,
          accountBranch,
          accountNumber,
          imageSrc,
          imageSrcSet,
          className }, key) in paymentImages"
        :key="key"
        :class="getClassName(
          isMobileScreenSize,
          getBankIconClassName('d-flex option mb-2', className),
          'mobile')"
      >
        <div
          :class="getClassName(
            isMobileScreenSize,
            'option-left d-flex justify-start align-center',
            'mobile')"
        >
          <img
            class="image mr-4"
            :src="imageSrc"
            :srcset="imageSrcSet"
          >
          <div>
            <div class="text">
              {{ getAccountName(accountName) }}
            </div>
            <div class="text">
              {{ getAccountBranch(accountBranch) }}
            </div>
          </div>
        </div>
        <div
          :class="getClassName(
            isMobileScreenSize,
            'option-right d-flex justify-end align-center',
            'mobile')"
        >
          <span class="text-account-number">{{ getAccountฺNumber(accountNumber) }}</span>
        </div>
      </div>
      <!-- <div class="pl-5">
        <img
          v-for="(optionIconImage, key) in paymentOptionIcons"
          :key="key"
          :src="optionIconImage"
          class="payment-option-image mr-2"
        >
      </div> -->
    </div>
    <!-- coupon -->
    <div class="mb-4">
      <div class="sub-title mr-2 mb-2">
        โค้ดส่วนลด:
      </div>
      <div class="d-flex flex-row">
        <div class="coupon-text-field">
          <v-text-field
            v-model="couponCodeValue"
            label="NR200BTH"
            solo
          />
        </div>
        <v-btn
          class="confirm-btn"
          color="secondary"
          outlined
          @click="onConfirmCoupon"
        >
          ยืนยัน
        </v-btn>
      </div>

      <div v-if="isCouponCodeValid">
        <img
          :src="tickIcon"
          class="mr-2"
        >
        <span class="discount-text">ได้รับโค้ดส่วนลด {{ discountPrice }} บาท</span>
      </div>
      <div v-else-if="isShowInvalidCouponText">
        <v-icon
          class="invalid-coupon-icon mr-2"
          small
        >
          mdi-close-circle
        </v-icon>
        <span class="invalid-coupon-text">ไม่พบรหัสส่วนลดนี้</span>
      </div>
    </div>

    <!-- summary -->
    <div class="summary d-flex flex-column mb-6">
      <div class="d-flex justify-space-between pl-6 pr-6">
        <span class="summary-title-text">ราคารวม</span>
        <span class="summary-price-text">{{ formatPriceText(summaryPrice) }}</span>
      </div>
      <div class="d-flex justify-space-between pl-6 pr-6">
        <span class="summary-title-text">ค่าจัดส่ง</span>
        <span class="summary-price-text">{{ formatPriceText(deliveryPrice) }}</span>
      </div>
      <div class="d-flex justify-space-between pl-6 pr-6">
        <span class="summary-title-text">โค้ดส่วนลด</span>
        <span class="summary-price-text discount-color">-{{ formatPriceText(discountPrice) }}</span>
      </div>
      <div class="summary-highlight d-flex flex-column">
        <div class="d-flex justify-space-between pl-4 pr-3">
          <span class="summary-highlight-title-text">รวมทั้งสิ้น</span>
          <span class="summary-highlight-value-text">{{ totalPrice }}</span>
        </div>
        <div class="d-flex justify-space-between pl-4 pr-3">
          <span class="summary-highlight-sub-title-text">ได้รับ Capsule ทั้งหมด</span>
          <span class="summary-highlight-sub-title-text value">{{ summaryCapsule | formatNumberFilter }} Capsule Points</span>
        </div>
      </div>
    </div>

    <!-- Confirm order -->
    <v-btn
      class="confirm-order__btn mb-4"
      color="secondary"
      height="48"
      :loading="isSubmitting"
      @click="confirmOrder(totalPrice)"
    >
      ยืนยันการสั่งซื้อ
    </v-btn>
    <span class="note-text">
      *หมายเหตุ: กรุณาโอนเงินภายใน 30 วันหลังจากการยืนยันการสั่งซื้อสำเร็จ
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getClassName } from '@/helpers/className';
import { formatPrice, formatNumber } from "@/helpers/number";
import { getBankDetail } from '@/helpers/payment';

import KbtgIcon from '@/assets/images/logo/kbtg.png';
import KrungthaiIcon from '@/assets/images/logo/krungthai.png';
import KrungthepIcon from '@/assets/images/logo/krungthep.png';
import ScbIcon from '@/assets/images/logo/scb.png';
import TickIcon from '@/assets/images/logo/tick.png';

export default {
  name: 'Payment',
  filters: {
    formatNumberFilter(value) {
      return formatNumber(value, 0);
    }
  },
  props: {
    couponCode: {
      type: String,
      default: () => '',
    },
    deliveryPrice: {
      type: Number,
      default: () => 0,
    },
    discountPrice: {
      type: Number,
      default: () => 0,
    },
    isCouponCodeValid: {
      type: Boolean,
      default: () => false,
    },
    isConfirmCouponCode: {
      type: Boolean,
      default: () => false,
    },
    paymentOption: {
      type: Number,
      default: () => 0,
    },
    paymentOptions: {
      type: Array,
      default: () => [],
    },
    paymentOptionRules: {
      type: Array,
      default: () => [],
    },
    confirmOrder: {
      type: Function,
      default: () => {
      },
    },
    summaryPrice: {
      type: Number,
      default: () => 0,
    },
    summaryCapsule: {
      type: Number,
      default: () => 0,
    },
    onConfirmCoupon: {
      type: Function,
      default: () => {
      },
    },
    pickupLocation: {
      type: String,
      default: () => '',
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tickIcon: TickIcon,
    paymentOptionIcons: [
      KbtgIcon,
      KrungthaiIcon,
      KrungthepIcon,
      ScbIcon,
    ],
    paymentImages: [getBankDetail('กสิกรไทย')],
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    couponCodeValue: {
      get() {
        return this.couponCode;
      },
      set(value) {
        const result = value.toUpperCase();
        this.$emit('setCouponCode', result);
      },
    },
    paymentOptionValue: {
      get() {
        return this.paymentOption;
      },
      set(value) {
        this.$emit('setPaymentOption', value);
      },
    },
    totalPrice() {
      const sum = (this.summaryPrice + this.deliveryPrice) - this.discountPrice;
      if (sum < 0)
        return 0;
      return this.formatPriceText(sum);
    },
    isShowInvalidCouponText() {
      return this.isConfirmCouponCode && !this.isCouponCodeValid;
    },
  },
  methods: {
    formatPriceText(price) {
      return formatPrice(price);
    },
    getAccountName(accountName) {
      return `ชื่อบัญชี ${accountName}`;
    },
    getAccountBranch(accountBranch) {
      return `สาขา ${accountBranch}`;
    },
    getAccountฺNumber(accountNumber) {
      return `เลขที่บัญชี ${accountNumber}`;
    },
    getBankIconClassName(defaultClassName, className) {
      return `${defaultClassName} ${className}`;
    },
    getClassName,
  }
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.payment-option-image {
  width: 40px;
  height: 40px;
}

.coupon-text-field {
  width: 100%;
  margin-right: 32px;

  .v-input__slot {
    min-height: 38px !important;
    border: solid 1px #d1d3d4 !important;
    box-shadow: none !important;
    margin-right: 32px;
    margin-bottom: 0;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin: 0;
  }
}

.confirm-btn {
  height: 38px !important;
  letter-spacing: normal;
}

.confirm-order__btn {
  letter-spacing: normal;
}

.discount-text {
  color: $green-color;
}

.summary {
  padding: 12px 12px 16px;
  border-radius: 6px;
  border: solid 1px $white-01;
  background-color: $white-01;

  &-title-text {
    font-family: Sarabun;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $black-00;
  }

  &-price-text {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: $black-00;

    &.discount-color {
      color: $red-color-00;
    }
  }
}

.summary-highlight {
  margin: 8px 0 0;
  padding: 8px 12px 13px;
  border-radius: 6px;
  border: dashed 1px $grey-01;
  background-color: $white-02;

  &-title-text {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $black-00;
  }

  &-value-text {
    font-family: Sarabun;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: $primary-color-02;
  }

  &-sub-title-text {
    font-family: Sarabun;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;

    &.value {
      color: $primary-color-02;
    }
  }
}

.note-text {
  font-family: Sarabun;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $grey-02;
}

.invalid-coupon-icon {
  color: $red-color-00 !important;
}

.invalid-coupon-text {
  color: $red-color-00;
}

.payment-option {
  .option {
    width: 100%;
    padding: 2%;
    border-radius: 6px;

    &.mobile {
      flex-direction: column;
    }

    &.kbank {
      border: solid 1px rgba(0, 168, 66, 0.2);
      background-color: rgba(0, 168, 66, 0.05);
    }

    &.krungthai {
      border: solid 1px rgba(0, 189, 236, 0.2);
      background-color: rgba(0, 189, 236, 0.05);
    }

    &.krungthep {
      border: solid 1px rgba(0, 50, 159, 0.2);
      background-color: rgba(98, 138, 225, 0.05);
    }

    &.scb {
      border: solid 1px rgba(85, 38, 135, 0.2);
      background-color: rgba(171, 126, 219, 0.05);
    }

    &-left {
      width: 50%;

      .image {
        width: 40px;
        height: 40px;
      }

      &.mobile {
        width: 100%;
      }
    }

    &-right {
      width: 50%;

      &.mobile {
        width: 100%;
      }
    }

    .text {
      @include sarabun;
      font-size: 12px;
      line-height: 1.5;
      color: $black-00;
    }

    .text-account-number {
      @include sarabun;
      font-size: 14px;
      line-height: 1.57;
      color: $black-00;
    }
  }
}
</style>
