<template>
  <v-container class="d-flex flex-column mb-16">
    <OrderSummary
      ref="orderSummary"
      :delivery-type="pickupLocation"
    />
    <v-form
      ref="form"
      v-model="isFormValid"
      lazy-validation
    >
      <div :class="getClassName('summary-container', 'summary-container flex-column')">
        <div :class="getClassName('delivery-location', 'delivery-location mobile')">
          <Delivery
            :active-delivery-location="getDefaultDeliveryLocation"
            :delivery-locations="deliveryLocations"
            :pickup-location="pickupLocation"
            :pickup-location-rules="pickupLocationRules"
            @setPickupLocation="setPickupLocation"
          />
        </div>
        <div :class="getClassName('payment', 'payment mobile')">
          <Payment
            :coupon-code="couponCode"
            :delivery-price="getDeliveryPrice()"
            :discount-price="discountPrice"
            :is-coupon-code-valid="isCouponCodeValid"
            :is-confirm-coupon-code="isConfirmCouponCode"
            :summaryPrice="getSummaryPrice"
            :summaryCapsule="summaryCapsuleAfterDiscount"
            :payment-option="paymentOption"
            :payment-options="getPaymentOptions()"
            :payment-option-rules="paymentOptionRules"
            :confirm-order="confirmOrder"
            :on-confirm-coupon="handleConfirmCoupon"
            :pickup-location="pickupLocation"
            @setPaymentOption="setPaymentOption"
            @setCouponCode="setCouponCode"
            :isSubmitting="isSubmitting"
          />
        </div>
      </div>

      <OrderSuccess
        :is-show-order-success-dialog="isShowOrderSuccessDialog"
        @setIsShowOrderSuccessDialog="setIsShowOrderSuccessDialog"
        :user-credit="getUserCredit"
      />

      <OrderFailed
        :error-message="errorMessage"
        :is-show-order-failed-dialog="isShowOrderFailedDialog"
        @setIsShowOrderFailedDialog="setIsShowOrderFailedDialog"
      />
    </v-form>
    <Guarantee />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import {
  GET_QUOTATION_BY_ID,
  CONFIRM_QUOTATION,
  CONFIRM_COUPON,
  CLEAR_ITEM,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
  INIT_CART
} from '@/store/_actionTypes';

import Guarantee from '@/components/Guarantee.vue';

import OrderSummary from './components/OrderSummary.vue';
import Delivery from './components/Delivery.vue';
import Payment from './components/Payment.vue';
import OrderSuccess from './components/OrderSuccess.vue';
import OrderFailed from './components/OrderFailed.vue';

export default {
  name: 'Quotation',
  components: {
    Delivery,
    OrderSummary,
    OrderSuccess,
    OrderFailed,
    Payment,
    Guarantee,
  },
  data: () => ({
    couponCode: null,
    discountPrice: 0,
    isCouponCodeValid: false,
    isConfirmCouponCode: false,
    errorMessage: '',
    isFormValid: true,
    paymentOption: 0,
    pickupLocation: '',
    paymentOptionRules: [
      (v) => !!v || 'กรุณาเลือก รูปแบบการชำระเงิน',
    ],
    pickupLocationRules: [
      (v) => !!v || 'กรุณาเลือก สถานที่นัดรับ/จัดส่งสินค้า',
    ],
    isShowOrderSuccessDialog: false,
    isShowOrderFailedDialog: false,
    isSubmitting: false
  }),
  computed: {
    ...mapState('app', ['isMobileScreenSize']),
    ...mapState('user', ['userInfo', 'accessToken', 'shippings']),
    ...mapState('cart', ['capsuleConfig']),
    ...mapGetters('quotation', ['getQuotation']),
    ...mapGetters('user', ['getUserCredit', 'getDefaultDeliveryLocation']),
    ...mapGetters('cart', ['getNormalProductSelected', 'getSummaryPrice', 'getTotalShippingFee', 'getTotalCapsuleAsNumber']),
    deliveryLocations() {
      return this.shippings;
    },
    summaryCapsuleAfterDiscount() {
      return this.getTotalCapsuleAsNumber - Math.floor(this.discountPrice / this.capsuleConfig);
    },
  },
  async created() {
    await this[INIT_CART]();
    /*
    if (this.getNormalProductSelected.length === 0) {
      this.$router.push({ name: 'Shop' });
    }
    */

    if (this.getUserCredit) {
      this.paymentOption = 2;
    }

    this.paymentOptionRules.push((v) => {
      if (!this.getUserCredit) return true;
      if (this.getUserCredit && v === 2) return true;

      setTimeout(() => {
        this.paymentOption = 2;
      }, 1000);
      return 'ลูกค้าเครดิตไม่สามารถชำระเงินสดได้';
    })
  },
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    ...mapActions('quotation', [ GET_QUOTATION_BY_ID, CONFIRM_QUOTATION, CONFIRM_COUPON ]),
    ...mapActions('cart', [ CLEAR_ITEM, INIT_CART ]),
    getClassName(defaultClassName, mobileClassName) {
      return this.isMobileScreenSize ? mobileClassName : defaultClassName;
    },
    setPaymentOption(value) {
      this.paymentOption = value;
    },
    setPickupLocation(value) {
      if (value == 'general-provider')
        this.paymentOption = 2;

      this.pickupLocation = value;
    },
    async confirmOrder(totalPrice) {
      if (!this.$refs.form.validate()) return;
      if (this.isRequireShippingType()) {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('กรุณาระบุที่อยู่จัดส่ง');
        this[CHANGE_SNACKBAR_COLOR]('red');
        return;
      }
      const productItems = this.getNormalProductSelected.map(item => {
        return {
          productId: item.id,
          qty: item.qty,
        };
      })
      const order = {
        userId: this.userInfo.id,
        shippingId: this.getDefaultDeliveryLocation ? this.getDefaultDeliveryLocation.id : null,
        shippingType: this.getShippingType(this.pickupLocation),
        paymentType: this.getPaymentType(this.paymentOption),
        promoCode: this.couponCode,
        productItems,
      }

      try {
        this.isSubmitting = true;
        const result = await this[CONFIRM_QUOTATION]({ accessToken: this.accessToken, order });

        if (result) {
          this.$gtag.event('conversion', {
            'send_to': 'AW-966302140/FZlsCP_pxYQDELyz4swD',
            'value': totalPrice,
            'currency': 'THB',
            'transaction_id': ''
          });
          this.setIsShowOrderSuccessDialog(true);
          this[CLEAR_ITEM]();
        } else {
          this.setIsShowOrderFailedDialog(true);
        }
      } catch (error) {
        this.setIsShowOrderFailedDialog(true);
        this.setErrorMessage(error.response.data.error || error.message || '');
      } finally {
        this.isSubmitting = false;
      }
    },
    setIsShowOrderSuccessDialog(value) {
      this.isShowOrderSuccessDialog = value;
    },
    setIsShowOrderFailedDialog(value) {
      this.isShowOrderFailedDialog = value;
    },
    setErrorMessage(msg) {
      this.errorMessage = msg;
    },
    setCouponCode(value) {
      this.couponCode = value;
    },
    isRequireShippingType() {
      if (this.pickupLocation === 'branch_1' || this.pickupLocation === 'branch_2' || this.pickupLocation === 'shop-provider')
        return false;

      if (!this.getDefaultDeliveryLocation)
        return true;
    },
    getDeliveryPrice() {
      if(this.pickupLocation === 'general-provider')
        return this.getSummaryPrice >= 7000 ? this.getTotalShippingFee : this.getTotalShippingFee + 50;

      return 0;
    },
    getShippingType(pickupLocation) {
      switch (pickupLocation) {
        case 'branch_1':
          return 'store_pickup'
        case 'branch_2':
          return 'store_pickup'
        case 'shop-provider':
          return 'store_delivery'
        case 'chaiyapoom-district':
          return 'city_pickup'
        case 'general-provider':
          return 'logistic'
        default:
          return '';
      }
    },
    getPaymentType(paymentOption) {
      switch (paymentOption) {
        case 1:
          return 'cash';
        case 2:
          return 'transfer';
        default:
          return '';
      }
    },
    getPaymentOptions() {
      const paymentOptions = [
        {
          title: 'ชำระเงินสด',
          value: 1,
        },
        {
          title: 'โอน/ชำระผ่านบัญชีธนาคาร',
          value: 2,
        },
      ];

      return this.getUserCredit ? paymentOptions.slice(1,2) : paymentOptions;
    },
    async handleConfirmCoupon() {
      if (!this.couponCode) return;

      try {
        const result = await this[CONFIRM_COUPON]({ accessToken: this.accessToken, couponCode: this.couponCode });
        const now = Date.now();

        if (result) {
          this.isConfirmCouponCode = true;
          const validFrom = Date.parse(result.startDate);
          const validTo = Date.parse(result.endDate);

          if (!(now >= validFrom && now <= validTo)) {
            this[SHOW_SNACKBAR](true);
            this[CHANGE_SNACKBAR_MESSAGE]('คูปองนี้หมดอายุไปแล้ว');
            this[CHANGE_SNACKBAR_COLOR]('red');
            this.isConfirmCouponCode = false;
            return;
          }

          const summary = this.getSummaryPrice;
          const deliveryCost = this.getDeliveryPrice;

          this.isCouponCodeValid = true;

          if (result.codeType === 'discount_amount') {
            this.discountPrice = result.discountAmount > summary ? summary : result.discountAmount;
          } else if (result.codeType === 'discount_percent') {
            const dc = (summary * result.discountPercent / 100).toFixed(2);
            this.discountPrice = dc > summary ? summary : dc;
          } else if (result.codeType === 'discount_shipping') {
            this.discountPrice = result.discountShipping > deliveryCost ? deliveryCost : result.discountShipping;
          } else {
            this.discountPrice = deliveryCost;
          }
        }
      } catch (error) {
        this.couponCode = null;
        this.isCouponCodeValid = false;
        this.isConfirmCouponCode = false;
        this.discountPrice = 0;

        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]("ไม่พบคูปองนี้ในระบบ หรือ คูปองนี้หมดอายุไปแล้ว");
        this[CHANGE_SNACKBAR_COLOR]('red');
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/variables';
  @import '@/styles/fonts';

  .title {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
  }
  .text-title {
    @include kanit;
    font-size: 20px;
    line-height: 1.5;
    color: $black-00;
  }
  .sub-title {
    @include sarabun;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    color: $black-00;
  }
  .quotation {
    display: flex;
    padding: 0 7% 4% 7%;

    .v-messages__message {
      margin-bottom: 16px;
    }
  }
  .summary-container {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
  }
  .delivery-location {
    width: 60%;
    padding-right: 10%;

    &.mobile {
      width: 100%;
      padding: 0;
    }
  }
  .payment {
    width: 40%;
    &.mobile {
      width: 100%;
    }
  }
  .v-label {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: #999999 !important;
  }
</style>
