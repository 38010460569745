<template>
  <div class="mt-3">
    <div class="text-title mb-3">
      สรุปรายการสั่งซื้อ
    </div>
    <div class="order-table">
      <v-data-table
        :headers="headers"
        :items="getNormalProductSelected"
        disable-sort
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header.order="{ header }">
          <div class="text header-left">
            {{ header.text }}
          </div>
        </template>

        <template v-slot:header.price="{ header }">
          <div class="text header">
            {{ header.text }}
          </div>
        </template>

        <template v-slot:header.amount="{ header }">
          <div class="text header">
            {{ header.text }}
          </div>
        </template>

        <template v-slot:header.unit="{ header }">
          <div class="text header">
            {{ header.text }}
          </div>
        </template>

        <template v-slot:header.totalPrice="{ header }">
          <div class="text header">
            {{ header.text }}
          </div>
        </template>

        <template v-slot:item.order="{ item }">
          <div :class="getClassName(isMobileScreenSize, 'd-flex', 'justify-space-between align-start')">
            <div
              v-if="getProductImage(item)"
              class="product-image-container mr-4"
            >
              <img
                :src="getProductImage(item)"
                class="product-image"
              >
            </div>
            <div>
              <div>
                <div class="order-title-text">
                  <a :href="`/product/${item.id}`" style="text-decoration: none;">{{ getTitle(item) }}</a>
                </div>
                <div class="order-subtitle-text">
                  {{ getSubTitle(item) }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.price="{ item }">
          <div class="d-flex justify-end">
            <span
              v-if="isDiscountPromotionValid(item)"
              class="text discount-price mr-2"
            >{{ getPriceRateItemWithoutDiscount(item) }}</span>
            <span class="text p-relative">
              {{ getPriceRateItem(item) }}
              <div
                v-if="isShippingFeeExisted(item)"
                class="d-flex justify-end shipping-fee-container"
              >
                <span class="text shipping-fee-title mr-2">ค่าส่ง</span>
                <span class="text shipping-fee">{{ getPriceText(item.deliveryFee) }}</span>
              </div>
            </span>
          </div>
        </template>

        <template v-slot:item.amount="{ item }">
          <div class="d-flex justify-end">
            <div class="p-relative">
              <span class="text">{{ getAmountText(item.qty) }}</span>
              <span
                v-if="isFreeGiftPromotionValid(item)"
                class="text"
              > {{ getExtraAmountText(item) }}</span>
              <div
                v-if="isShippingFeeExisted(item)"
                class="d-flex justify-end justify-md-center shipping-fee-container"
              >
                <span class="text shipping-fee">{{ getAmountText(item.qty) }}</span>
                <span
                  v-if="isFreeGiftPromotionValid(item)"
                  class="text shipping-fee ml-1"
                > {{ getExtraAmountText(item) }}</span>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.unit="{ item }">
          <div class="d-flex justify-end">
            <span class="text">{{ item.unit }}</span>
          </div>
        </template>

        <template v-slot:item.totalPrice="{ item }">
          <div class="d-flex justify-end">
            <span class="text total-price p-relative">
              {{ getItemTotalPrice(item) }}
              <div
                v-if="isShippingFeeExisted(item)"
                class="d-flex justify-end justify-md-center shipping-fee-container"
              >
                <span class="text shipping-fee">{{ getTotalShippingFee(item) }}</span>
              </div>
            </span>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getClassName } from '@/helpers/className';
import { formatNumber, formatPrice } from '@/helpers/number';
import {
  isDiscountPromotionValid,
  isFreeGiftPromotionValid,
  calculateFreeBuyFreeGift,
  applyDiscountPromotion,
  getPriceRate
} from "@/helpers/promotion";

export default {
  name: 'OrderSummary',
  props: {
    orderList: {
      type: Array,
      default: () => [],
    },
    deliveryType: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    headers: [
      { text: 'รายการสินค้า', value: 'order' },
      { text: 'ราคาต่อหน่วย', value: 'price' },
      { text: 'จำนวน (+แถม)', value: 'amount' },
      { text: 'หน่วย', value: 'unit' },
      { text: 'ราคารวม', value: 'totalPrice' },
    ],
  }),
  computed: {
    ...mapState('app', [ 'isMobileScreenSize' ]),
    ...mapGetters('cart', [ 'getNormalProductSelected', 'getCapsuleProductSelected' ]),
  },
  methods: {
    getClassName,
    isDiscountPromotionValid,
    isFreeGiftPromotionValid,
    calculateFreeBuyFreeGift,
    getTitle(item) {
      return item.name
    },
    getSubTitle(item) {
      return item.genericName;
    },
    getProductImage(item) {
      return item.image;
    },
    getPriceRateItemWithoutDiscount: (item) => {
      return `฿${formatNumber(getPriceRate(item))}`;
    },
    getPriceRateItem: (item) => {
      return `฿${formatNumber(applyDiscountPromotion(item, getPriceRate(item)))}`;
    },
    getItemTotalPrice: (item) => {
      return `฿${formatNumber(applyDiscountPromotion(item, getPriceRate(item)) * item.qty)}`;
    },
    getPriceText(price) {
      return Number.isInteger(price) && price > 0 ? formatPrice(price) : price;
    },
    getAmountText(amount) {
      return formatNumber(amount, 0);
    },
    getExtraAmountText(item) {
      return `(+${this.getExtraAmount(item)})`;
    },
    getExtraAmount(item) {
      const freeGift = isFreeGiftPromotionValid(item) ? calculateFreeBuyFreeGift(item.promotionFreeBuy, item.promotionFreeGift, item.qty) : 0;
      return parseInt(freeGift);
    },
    isLastOrder(orderIndex) {
      return this.orderList.length - 1 === orderIndex;
    },
    isDiscountPriceExisted(item) {
      return !!item.discountPrice;
    },
    isExtraAmountExisted(item) {
      return !!item.extraAmount;
    },
    isShippingFeeExisted(item) {
      return !!item.deliveryFee && this.deliveryType === 'general-provider';
    },
    getTotalShippingFee(item) {
      const totalShippingFee = (item.qty + this.getExtraAmount(item)) * item.deliveryFee;
      return `฿${formatNumber(totalShippingFee, 4)}`;
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.order-table {
  width: 100%;
  border-radius: 6px;
  border: solid 2px $white-01;
  background-color: $white-00;

  .v-data-table-header-mobile {
    display: none;
  }

  th {
    border: 0 !important;

    span {
      width: 76px;
      height: 22px;
      margin: 0 20px 16px 0;
      font-family: Sarabun;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #242424;
    }
  }

  td {
    border: 0 !important;
  }

  table {
    border-spacing: 0 1em !important;
  }
}

.product-image-container {
  width: 80px;
  height: 80px;
  padding: 9px 8px 8px 9px;
  border-radius: 4px;
  border: solid 1px $white-01;
  background-color: $white-00;
}

.product-image {
  width: 100%;
  height: 100%;
}

.order-title-text {
  font-family: Sarabun;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $black-00;
}

.order-subtitle-text {
  font-family: Sarabun;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $grey-00;
}

.column {
  width: 30px !important;
}

.footer {
  display: flex;

  .left {
    width: 50%;
  }

  .right {
    width: 50%;
  }
}

.text {
  @include sarabun;
  font-size: 16px;

  &.discount-price {
    text-decoration: line-through;
    color: $grey-02;
  }

  &.total-price {
    font-weight: 600;
    line-height: 1.5;
    color: $primary-color-02;
  }

  &.header {
    font-size: 14px;
    line-height: 1.57;
    text-align: right;
    font-weight: normal;
    color: $black-00;

    &-left {
      font-size: 14px;
      line-height: 1.57;
      text-align: left;
      font-weight: normal;
      color: $black-00;
    }
  }

  &.shipping-fee-title,
  &.shipping-fee {
    color: red;
    font-size: 12px;
    font-weight: normal;
  }

  &.shipping-fee-title {
    min-width: 30px;
  }
}

.p-relative {
  position: relative;
}

.shipping-fee-container {
  position: absolute;
  width: 100%;
}

tr:first-child td:first-child > .v-data-table__mobile-row__cell {
  margin-top: 0;
}

td:first-child > .v-data-table__mobile-row__cell {
  width: 100%;
  margin-top: 32px;
}

td:first-child > .v-data-table__mobile-row__header {
  display: none;
}
</style>
